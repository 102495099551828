import {createSlice} from '@reduxjs/toolkit';
import {get, del, patch} from 'core/configureRequest';
import {notifyError, notifySuccessWithTimeout} from 'features/notification/slice';

const initialState = {
    businesses: [],
    isLoading: false,
    isDeleting: false,
    isEditing: false,
};

const birdeyeBusinessSlice = createSlice({
    name: 'birdeyeBusiness',
    initialState,
    reducers: {
        startFetch(state, action) {
            return {
                ...state,
                isLoading: true,
            };
        },
        finishFetch(state, action) {
            return {
                ...state,
                isLoading: false,
            };
        },
        receiveBirdeyeBusinesses(state, action) {
            return {
                ...state,
                businesses: action.payload,
                isLoading: false,
            };
        },
        startDelete(state, action) {
            return {
                ...state,
                isDeleting: true,
            };
        },
        finishDelete(state, action) {
            return {
                ...state,
                isDeleting: false,
            };
        },
        startEdit(state, action) {
            return {
                ...state,
                isEditing: true,
            };
        },
        finishEdit(state, action) {
            return {
                ...state,
                isEditing: false,
            };
        },
    },
});

export const {startFetch} = birdeyeBusinessSlice.actions;
export const {finishFetch} = birdeyeBusinessSlice.actions;
export const {receiveBirdeyeBusinesses} = birdeyeBusinessSlice.actions;
export const {startDelete} = birdeyeBusinessSlice.actions;
export const {finishDelete} = birdeyeBusinessSlice.actions;
export const {startEdit} = birdeyeBusinessSlice.actions;
export const {finishEdit} = birdeyeBusinessSlice.actions;

export const fetchBirdeyeBusinesses = () => (dispatch, getState) => {
    dispatch(startFetch());
    return get(
        `/proxy/api/listing/birdeye-business/`,
    )
        .then((data) => {
            dispatch(receiveBirdeyeBusinesses(data.data.birdeye_businesses));
        })
        .catch((e) => {
            dispatch(finishFetch());
            return Promise.reject(dispatch(notifyError('There was a problem loading Birdeye Business info')));
        });
};

export const deleteBirdeyeBusiness = (listingId) => (dispatch, getState) => {
    dispatch(startDelete());
    return del(`/proxy/api/listing/${listingId}/birdeye-business/`)
        .then((data) => {
            dispatch(fetchBirdeyeBusinesses());
        }).then((data) => {
            dispatch(finishDelete());
        })
        .catch((e) => {
            dispatch(finishDelete());
            return Promise.reject(dispatch(notifyError('There was a roblem deleting Birdeye Business ID for listing ' + listingId)));
        });
};

export const editBirdeyeBusiness = (listingId, birdeyeBusinessId) => (dispatch, getState) => {
    dispatch(startEdit());
    return patch(
        `/proxy/api/listing/${listingId}/birdeye-business/`,
        { business_id: birdeyeBusinessId }
    )
        .then((data) => {
            dispatch(fetchBirdeyeBusinesses());
        }).then((data) => {
            dispatch(finishEdit());
        })
        .catch((e) => {
            dispatch(finishEdit());
            return Promise.reject(dispatch(notifyError('There was a problem updating Birdeye Business ID for listing ' + listingId)));
        });
};

export default birdeyeBusinessSlice.reducer;